import React, {useContext, useEffect, useState} from "react";
import UserData from "../../store/User/UserData";
import "../../styles/pages/AdhocInvestigation/AdhocInvestigation.scss";
import {modules, startPoints} from "./config";
import TenantDropDownStyles from "../../styles/pages/Onboarding/TenantDropDownStyles";
import Select from "react-select";
import LoaderButton from "../../components/Button/LoaderButton";
import {captureSentryError} from "../../util/sentry";
import ResultsPanel from "./components/ResultsPanel";
import SummaryPanel from "./components/SummaryPanel";
import CommentModal from "./components/CommentModal";
import ErrorIcon from "@mui/icons-material/Error";

const AdhocInvestigation = () => {
  const [userData] = useContext(UserData);
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedStartPoint, setSelectedStartPoint] = useState();
  const [selectedModules, setSelectedModules] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [resultsLoading, setResultsLoading] = useState(false);
  const [results, setResults] = useState({modules: []});
  const [error, setError] = useState();
  const [commentModalData, setCommentModalData] = useState(false);

  useEffect(() => {
    if (selectedStartPoint)
      setSelectedModules(
        modules.filter((module) =>
          selectedStartPoint.values.includes(module.value)
        )
      );
    else setSelectedModules([]);
  }, [selectedStartPoint]);

  const validateInput = () => {
    if (!selectedTenant) {
      setError("Tenant must be selected.");
      return false;
    }
    if (selectedModules.length === 0) {
      setError("At least one module must be selected.");
      return false;
    }
    if(!searchValue){
      setError("Must enter a start point value.");
      return false;
    }
    return true;
  };

  const handleSearch = async () => {
    if (!validateInput()) return;
    try {
      if (selectedModules) {
        setResultsLoading(true);
        setResults({
          llm_summary: {},
          modules: selectedModules.map((module) => ({
            module: module.label,
          })),
        });
        await fetchInvestigationData();
      }
    } catch (e) {
      setResultsLoading(false);
      captureSentryError(e, userData, "GET adhoc investigation results");
      setError(e.message);
    }
  };

  const fetchInvestigationData = async () => {
    try {
      setError(false);

      // Simulate initial API call with empty data
      const initialResults = {
        answer: {
          llm_summary: {},
          modules: [],
        },
        complete: false,
      };

      pollResults(initialResults, 1);
    } catch (e) {
      setError(e.message);
      captureSentryError(
        e,
        userData,
        "error in fetchInvestigationData API in AchocInvestigation.js"
      );
    }
  };

  const pollResults = async (results, pollCount) => {
    try {
      // Simulate API call with fake data
      const additionalResults1 = [
        {
          id: 1,
          module: "External Scan Range",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
      ];
      const additionalResults2 = [
        {
          id: 2,
          module: "Reputation",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
        {
          id: 3,
          module: "Geolocation",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
      ];
      if (pollCount === 1) {
        // Initial poll returns empty
        setResults((prevState) => ({
          ...prevState,
          modules: prevState.modules?.map((mod) => ({
            ...mod,
            text: "",
          })),
          llm_summary: {
            summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ' +
              'labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ' +
              'nisi ut aliquip ex ea commodo consequat.',
            timeline: [{timestamp: 1729982983, note: "User X opened the malicious file..."},
              {timestamp: 1729723783, note: "Process xyzzy was triggered."}],
            affected_assets: ["123.23.123", "321.32.321", "456.56.654"]
          }
        }));
      } else if (pollCount === 2) {
        // Second poll returns partial data
        setResults((prevState) => ({
          ...prevState,
          modules: prevState.modules?.map(
            (mod) =>
              additionalResults1.find((res) => res.module === mod.module) || mod
          ),
        }));
      } else if (pollCount === 3) {
        // Third poll returns complete data
        setResults((prevState) => ({
          ...prevState,
          modules: prevState.modules?.map(
            (mod) =>
              additionalResults2.find((res) => res.module === mod.module) || mod
          ),
        }));
        results.complete = true;
      }

      if (results.complete) {
        setResultsLoading(false);
      } else {
        setTimeout(async () => {
          pollResults(results, pollCount + 1);
        }, 5000);
      }
    } catch (e) {
      setError(e.message);
      captureSentryError(
        e,
        userData,
        "pollResults API in AchocInvestigation.js"
      );
    }
  };


  const selectConfigs = [
    {
      label: "Tenant",
      className: "tenant",
      options: userData.userTenants,
      value: selectedTenant,
      onChange: setSelectedTenant,
    },
    {
      label: "Start Point",
      className: "start-point",
      options: startPoints,
      value: selectedStartPoint,
      onChange: setSelectedStartPoint,
    },
    {
      label: "Investigation Tools",
      className: "investigation-tools",
      options: modules,
      value: selectedModules,
      onChange: setSelectedModules,
      isMulti: true,
    },
  ];

  return (
    <div className={"adhoc-container"}>
      {commentModalData && (
        <CommentModal
          data={commentModalData}
          handleClose={() => setCommentModalData()}
        />
      )}
      <div className={"header-row"}>
        <p className={"page-title"}>ADHOC INVESTIGATION</p>
      </div>

      <div className="adhoc-content">
        <div className="search-container">
          {selectConfigs.map((config, index) => (
            <div className={`input-container ${config.className}`} key={index}>
              <div className="label">{config.label}</div>
              <Select
                className={`selection ${config.className}`}
                styles={TenantDropDownStyles}
                options={config.options}
                value={config.value}
                onChange={config.onChange}
                isMulti={config.isMulti || false}
              />
            </div>
          ))}
          <div className="input-container search-box">
            <input
              className={`input-box`}
              value={searchValue}
              placeholder={"Enter the Start Point Value"}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className="input-container submit-button-container">
            <LoaderButton
              className={"submit-button"}
              text={"SUBMIT"}
              onClick={() => handleSearch()}
              loading={resultsLoading}
            />
          </div>
          {error && (
            <div className="error-message">
              <ErrorIcon className="err-icon"/> {error}
            </div>
          )}
        </div>

        <div className="results-container flex">
          <div className="module-results">
            {results?.modules?.map((result) => (
              <ResultsPanel
                data={result}
                key={result.module}
                setCommentModalData={setCommentModalData}
              />
            ))}
          </div>
          {results.hasOwnProperty("llm_summary") &&
          <SummaryPanel loading={resultsLoading} data={results.llm_summary}/>}
        </div>
      </div>
    </div>
  );
};

export default AdhocInvestigation;
