import React from 'react';
import "../../../styles/pages/AdhocInvestigation/SummaryPanel.scss";
import {formatTime} from "../../../util/format";
import {Loader} from "../../../components/Loader/Loader";

const SummaryPanel = ({data, loading}) => {
  return (
    <div className={"summary-panel-container"}>
      <div className={"header summary"}>Summary</div>
      {!loading ? <p>{data?.summary}</p> : <Loader/>}
      <div className={"header timeline"}>Timeline</div>
      {!loading ? data?.timeline.map(item =>
        <p><span className={"timestamp"}>{formatTime(item.timestamp)}</span>{item.note}</p>) : <Loader/>}
      <div className={"header affected_assets"}>Affected Assets</div>
      {!loading ? data?.affected_assets.map(val => <p>{val}</p>) : <Loader/>}
    </div>
  );
};

export default SummaryPanel;