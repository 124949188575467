export const modules = [
  {
    label: "External Scan Range",
    value: "external_scan_range",
  },
  { label: "Reputation", value: "reputation" },
  { label: "Geolocation", value: "geolocation" },
];

export const startPoints = [
  { label: "All Modules", values: modules.map((m) => m.value) },
];
